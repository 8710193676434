/* eslint-disable react/no-danger */
import React from 'react';
import { styled } from 'linaria/react';
import Layout from '../components/Layout/Layout';
import useSiteMetaData from '../static_queries/useSiteMetadata';
import { media } from '../styles/utils.ts';

const InfoSection = styled.section`
  max-width: 800px;
  padding: 1.5rem 1.25rem;
  p {
    color: var(--grey);
    font-size: 1.1rem;
  }
  h2 div p {
    margin-bottom: 1rem;
  }
  ${media.medium} {
    padding: 2rem;
  }

  ${media.large} {
    padding: 3rem;
  }
`;

export default function Info() {
  const { infoData } = useSiteMetaData();
  return (
    <Layout page="info" title="aboutroots | info" withBgImage>
      <InfoSection>
        <h2>
          <div dangerouslySetInnerHTML={{ __html: infoData.description }} />
          <div dangerouslySetInnerHTML={{ __html: infoData.cta }} />
        </h2>
      </InfoSection>
    </Layout>
  );
}
